import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import './assets/default.css'
import router from "./router";
import store from "./store";
import * as components from './components'

const Vue = createApp(App)

for (const key in components) {
	const component = components[key]
	Vue.component(key, component)
}

Vue.use(store)
	.use(router)
	.mount('#app')
