<template>
	<div :class="['r-data-list-add-C']" :style="[]">
		<edit-items
			:label="itm.title"
			v-model="indata[itm.key]"
			v-for="(itm, i) in opt"
			:key="i"
			:opt="itm"
			pos="add"
			v-model:valid="isValid[itm.key]"
		/>
		<r-btn @click="saveAdd" bgcolor="#15880d" :disabled="canSave">Kaydet</r-btn>
		<r-btn @click="cancelAdd" bgcolor="#868686">Vazgeç</r-btn>
	</div>
</template>
<script>
import { ref, computed } from 'vue'
import EditItems from './EditItems'
export default {
	props: {
		opt: Array,
		func: Function,
		show: Boolean,
	},
	components: {
		EditItems,
	},
	setup(props, { emit }) {
		const indata = ref({})
		const isValid = ref({})
		props.opt.forEach(itm => {
			if (itm.valid) isValid.value[itm.key] = false
		})
		const canSave = computed(() => {
			var dis = false
			Object.keys(isValid.value).forEach(key => {
				if (!isValid.value[key]) dis = true
			})
			return dis
		})
		const saveAdd = () => {
			props.func(indata.value)
			emit('update:show', false)
		}
		const cancelAdd = () => {
			indata.value = {}
			emit('update:show', false)
		}
		return { indata, saveAdd, cancelAdd, isValid, canSave }
	},
}
</script>
<style>
.r-data-list-add-C {
	position: absolute;
	top: 100%;
	right: 0;
	display: flex;
	background-color: #fff;
	padding: 16px;
	-webkit-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.3);
	border-top: 2px solid rgb(19, 1, 180);
	border-radius: 0 0 6px 6px;
	align-items: flex-start;
	flex-wrap: wrap;
	z-index: 9;
}
.r-data-list-add-item {
}
</style>
