<template>
	<teleport to="body">
		<div class="r-modal-back-C" v-if="isactive" @click="closeModal">
			<div
				class="r-modal-C"
				:style="{width: width || '', height: height || ''}"
				@click="disableClick"
			>
				<div class="r-modal-title-C">
					<slot name="title"><h4>{{ title }}</h4></slot>
				</div>
				<div class="r-modal-content-C" v-if="havecontent">
					<slot name="content">
						<r-flex v-html="content"> </r-flex>
					</slot>
				</div>
				<div class="r-modal-buttons-C">
					<slot name="buttons">
						<r-btn bgcolor="#868686" @click="closeModal">{{ canceltxt }}</r-btn>
						<slot name="otherbuttons"></slot>
					</slot>
				</div>
			</div>
		</div>
	</teleport>
</template>
<script>
export default {
	props: {
		title: String,
		content: String,
		canceltxt: {
			type: String,
			default: 'Vazgeç',
		},
		isactive: {
			type: Boolean,
			default: false,
		},
		width: Number,
		height: Number,
		havecontent: {
			type: Boolean,
			default: true,
		},
	},
	setup(props, {emit}) {
		const disableClick = (e) => {
			e.stopPropagation()
		}
		const closeModal = () => {
			emit('update:isactive', false)
		}
		return {disableClick, closeModal}
	},
}
</script>
<style>
.r-modal-back-C {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;
	z-index: 999;
}
.r-modal-C {
	background-color: #fff;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: column;
}
.r-modal-title-C {
	padding: 20px;
	width: 100%;
	border-bottom: 1px solid rgb(233, 233, 233);
}
.r-modal-content-C {
	padding: 20px;
	width: 100%;
	border-bottom: 1px solid rgb(233, 233, 233);
}
.r-modal-buttons-C {
	padding: 10px 16px;
	width: 100%;
	border-top: 1px solid rgb(233, 233, 233);
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
</style>
