/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import axios from 'axios'
import * as Cookies from 'js-cookie'
import router from '@/router'

export default createStore({
	state: {
		baseurl: 'https://ays.tempatak.com/api/',
		loggedin: false,
		username: '',
		userid: null,
	},
	mutations: {},
	actions: {
		getDatas: async ({ state, dispatch }, data) => {
			var header = {
				'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
			}
			if (data.pos !== 'login') {
				let j = await dispatch('authControl')
				if (!j) return
				else {
					header.Authorization = 'Bearer ' + j.t
				}
			}

			var urlparam = '?'
			if (data.params) {
				Object.keys(data.params).forEach((key, i) => {
					urlparam +=
						key +
						'=' +
						data.params[key] +
						(i === Object.keys(data.params).length - 1 ? '' : '&')
				})
			}
			const res = await axios
				.get(state.baseurl + data.api + urlparam, {
					headers: header,
				})
				.then((res) => {
					//state.errorapi = false
					return res.data
				})
				.catch((error) => {
					state.errorapi = true
					if (error.response.status === 401) {
						router.push('/giris')
						return
					}
					//console.log("geterr",error.response)
				})
			return res
		},
		postDatas: async ({ dispatch, state }, data) => {
			var opts = {
				headers: {},
			}
			if (data.pos !== 'login') {
				var j = await dispatch('authControl')
				if (!j) return
				else {
					opts.headers.Authorization = 'Bearer ' + j.t
				}
			}

			let sdata = data.data || {}
			if (data.headers !== undefined) opts.headers = data.headers
			const res = await axios
				.post(state.baseurl + data.api, sdata, opts)
				.then((res) => {
					//state.errorapi = false
					return res.data
				})
				.catch((error) => {
					state.errorapi = true
					//console.log("err",error)
					if (error.response.status === 401) {
						router.push('/giris')
						return
					}
				})
			return res
		},
		authControl: async ({ state }) => {
			let c = Cookies.get('aysarsiv')
			if (c) {
				let j = JSON.parse(c)
				if (!j.t) {
					Cookies.remove('aysarsiv')
					router.push('/giris')
				} else {
					state.userid = j.uid
					state.username = j.n
					return j
				}
			} else {
				router.push('/giris')
			}
		},
	},
	modules: {},
})
