<template>
	<nav
		id="nav-top"
		:class="['nav']"
	>
		<r-flex flx="7" class="nav-top-links r-flx-nop r-flx-tw-100">
			<r-flex class="r-flx-nop"> </r-flex
			><span class="r-check-no log-out-btn" @click="logOut"></span>
		</r-flex>
	</nav>
</template>
<script>
import * as Cookies from 'js-cookie'
import router from '@/router/'
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		const logOut = () => {
			Cookies.remove('tdataref')
			router.push('/giris')
		}

		return {
			logOut,
			store,
		}
	},
}
</script>
<style>
.nav-top-links {
	flex-wrap: nowrap !important;
	flex: 0 0 auto !important;
	align-items: center !important;
}
.top-nav-fixed {
	position: fixed;
	top: -100px !important;
}
.top-nav-fixed .log-out-btn {
	align-self: flex-end;
	background-color: transparent;
	margin-bottom: 4px;
}
.top-nav-fixed .log-out-btn:before,
.top-nav-fixed .log-out-btn:after {
	border-top: 2px solid #9c0000;
}
.r-nav-top-top-C {
	background-color: #eaeaea;
	padding: 8px;
	justify-content: center !important;
	align-items: center !important;
}
</style>
