<template>
	<div id="content-C">
		<router-view />
	</div>
</template>
<script>
import {useStore} from 'vuex'
import { onBeforeMount } from 'vue'
export default {
	setup() {
		const store = useStore()
		onBeforeMount(async () => {
			await store.dispatch('authControl')
		})
		return {
			store
		}
	},
}
</script>