<template>
	<button
		class="r-btn-C flx"
		@click="disableClick"
		:style="{ backgroundColor: bgcolor || '' }"
	>
		<slot name="button">
			<h5>
				<slot>{{ title }}</slot>
			</h5></slot
		>
	</button>
</template>
<script>
export default {
	props: {
		title: String,
		bgcolor: String,
	},
	setup() {
		const disableClick = () => {
			//e.stopPropagation()
		}
		return { disableClick }
	},
}
</script>
<style>
.r-btn-C {
	display: flex;
	padding: 6px;
	background-color: rgb(0, 56, 160);
	border-radius: 6px;
	cursor: pointer;
	color: #fff;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	margin: 2px;
	border: none;
	font-weight: 400;
}
.r-btn-C:disabled,
.r-btn-C:disabled:hover {
	background-color: rgb(226, 226, 226) !important;
}
.r-btn-C:focus {
	outline: none;
}
.r-btn-C:hover {
	background-color: rgb(233, 85, 0) !important;
}
</style>
