<template>
	<div class="r-checkbox-C" :style="pos === 'add' ? opt.addstyle : ''">
		<label
			><input
				type="checkbox"
				:value="val"
				:checked="isChecked"
				@change="updateVal"
				@click="disableClick"
			/>
			<span class="r-checkbox-checkmark"></span
			><span class="label">{{ label }}</span></label
		>
	</div>
</template>
<script>
import {computed} from 'vue'
export default {
	props: {
		label: [Number, String],
		modelValue: [Number, String, Array, Boolean],
		selectedTxt: Array,
		opt: Object,
		pos: String,
		val: [Number, String, Boolean],
	},
	setup(props, {emit}) {
		const isArray = computed(() => {
			return props.val ? true : false
		})
		const isChecked = computed(() => {
			if (isArray.value) {
				var ist = false
				props.modelValue.forEach((v) => {
					if (v.toString() === props.val.toString()) {
						ist = true
						return
					}
				})
				return ist
			} else {
				return props.modelValue
			}
		})
		const updateVal = (e) => {
			if (isArray.value) {
				var arr = []
				arr.splice(0, arr.length, ...props.modelValue)
				if (e.target.checked) {
					arr.push(props.val)
				} else {
					arr.splice(arr.indexOf(props.val), 1)
				}
				emit('update:modelValue', arr)
			} else {
				emit('update:modelValue', e.target.checked)
			}
			emit('change', e)
		}
		const disableClick = (e) => {
			e.stopPropagation()
		}
		return {updateVal, disableClick, isChecked}
	},
}
</script>
<style>
.r-checkbox-C {
	display: flex;
	position: relative;
	width: 100%;
}
.r-checkbox-C input {
	width: 0;
	height: 0;
	opacity: 0;
}
.r-checkbox-C label {
	padding: 6px;
	display: flex;
	width: 100%;
	align-items: center;
}
.r-checkbox-C label span {
	flex-shrink: 0;
}
.r-checkbox-checkmark {
	position: relative;
	height: 20px;
	width: 20px;
	background-color: #eee;
	border: 1px solid rgb(221, 221, 221);
	border-radius: 3px;
	margin-right: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.r-checkbox-C label:hover .r-checkbox-checkmark {
	border-color: rgb(173, 173, 173);
}
.r-checkbox-C label input:checked ~ .r-checkbox-checkmark {
	border-color: rgb(0, 56, 160);
}
.r-checkbox-checkmark:after {
	content: '';
	position: absolute;
	display: none;
}
.r-checkbox-C label input:checked ~ .r-checkbox-checkmark:after {
	display: flex;
}
.r-checkbox-C label .r-checkbox-checkmark:after {
	width: 4px;
	height: 8px;
	top: 2px;
	border: solid rgb(0, 56, 160);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
</style>
