import { createRouter, createWebHistory } from 'vue-router'
import RootPage from '@/RootPage'

const routes = [
	{
		path: '/',
		name: 'RootPage',
		component: RootPage,
		children: [
			{
				path: '/dashboard',
				name: 'dashboard',
				component: () =>
					import(/* webpackChunkName: "dashboard" */ '@/Pages/Dashboard.vue'),
			},
		],
	},
	{
		path: '/giris',
		name: 'login',
		component: () =>
			import(/* webpackChunkName: "login" */ '@/Pages/Login.vue'),
	},
	{
		path: '/kullanicilar',
		name: 'kullanicilarana',
		component: RootPage,
		children: [
			{
				path: '',
				name: 'kullanicilar',
				component: () =>
					import(
						/* webpackChunkName: "kullanicilar" */ '@/Pages/Kullanicilar.vue'
					),
			},
		],
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router
