<template>
	<div
		:class="[
			'r-data-list-W',
			opt.class && opt.class.wrapper ? opt.class.wrapper : '',
		]"
	>
		<div :class="['r-data-list-title-C flx w-100']" v-if="opt.title">
			<slot name="title" :title="opt.title" :opt="opt"
				><r-flex flx="6"
					><h3>
						{{ opt.title }}
					</h3></r-flex
				></slot
			>
			<slot name="titlebuttons">
				<div class="r-data-list-title-buttons-C">
					<slot name="titleextrabuttons"></slot>
					<r-input v-if="search" v-model="q" label="Ara" width="20%" />
					<add-new
						v-if="showaddnew"
						:opt="headdata"
						:func="saveNew"
						v-model:show="showaddnew"
					/>
					<slot name="titleextrabuttonsafter"></slot>
					<r-btn
						v-if="
							opt.buttons &&
								opt.buttons.add &&
								(opt.buttons.add.pos === 'title' ||
									opt.buttons.add.pos === undefined)
						"
						@click="addLine"
						:disabled="isEditMode || isAddMode"
						>{{
							(opt.buttons.edit && opt.buttons.add.txt) || 'Yeni Ekle'
						}}</r-btn
					>
				</div>
			</slot>
		</div>
		<div
			:class="[
				'r-data-list-C',
				opt.class && opt.class.list ? opt.class.list : '',
			]"
			v-if="loaded"
		>
			<div class="r-data-list-header-C flx flx-nowrap w-100" v-if="showHeader">
				<div
					class="r-data-list-header-col-C r-data-list-count-col flx"
					v-if="opt.count !== undefined && opt.count"
				>
					#
				</div>
				<div
					class="r-data-list-header-col-C r-data-list-select-col flx"
					v-if="selectable"
				>
					<r-checkbox v-model="selectall" @change="selectAllChange" />
				</div>
				<slot name="head" :opt="opt">
					<div
						v-if="intitle"
						class="r-data-list-header-col-C flx"
						:style="[(opt.intitle && opt.intitle.style) || '']"
					>
						<strong>{{ intitle }}</strong>
					</div>
					<div
						v-else
						:class="[
							'r-data-list-header-col-C flx',
							hcol.class || '',
							hcol.hclass || '',
						]"
						:style="[hcol.style || '', hcol.hstyle || '']"
						v-for="(hcol, i) in headdata"
						:key="'hcol' + i"
					>
						<slot :name="'hcol' + (i + 1)" :datacol="hcol" :idcol="i">
							<strong>{{
								typeof hcol.title === 'function'
									? hcol.title(hcol, i)
									: hcol.title
							}}</strong>
						</slot>
					</div>
					<div
						class="r-data-list-header-col-C flx r-col-btn-C"
						:style="[opt.buttons.style || '']"
						v-if="opt.buttons"
					>
						<r-btn
							v-if="
								opt.buttons && opt.buttons.add && opt.buttons.add.pos === 'head'
							"
							@click="addLine"
							:disabled="isEditMode || isAddMode"
							>{{
								(opt.buttons.edit && opt.buttons.add.txt) || 'Yeni Ekle'
							}}</r-btn
						>
					</div>
				</slot>
				<div
					:class="['r-data-list-collapse-ico', collapsed ? 'collapsed' : '']"
					v-if="opt.collapse !== undefined && opt.collapse"
					@click="collapsed = !collapsed"
				></div>
			</div>
			<div :class="['r-data-list-list-C flx-col']" v-if="!collapsed">
				<div class="r-data-list-nodata" v-if="!inData || inData.length === 0">
					<slot name="nodata">
						{{
							opt.nodatatxt
								? typeof opt.nodatatxt === 'function'
									? opt.nodatatxt()
									: opt.nodatatxt
								: 'Kayıt bulunamadı'
						}}
						<slot name="nodatain"></slot
					></slot>
				</div>

				<draggable
					v-model="inData"
					tag="transition-group"
					:item-key="dragkey"
					:disabled="disabledrag"
					v-bind="dragOptions"
					@start="DragStart"
					@end="DragEnd"
					:move="DragMove"
					:group="draggroup || ''"
				>
					<template #item="{ element, index }">
						<div
							:class="[
								'r-data-list-item r-data-list-line-C flx w-100',
								isEditMode && editLineIdi === index ? 'edit-mode' : '',
								wrapline ? 'flx-wrap' : 'flx-nowrap',
							]"
							@click="rowClick({ row: element, rowid: index })"
						>
							<slot name="row" :data="element" :id="index">
								<div
									class="r-data-list-col-C r-data-list-count-col flx"
									v-if="opt.count !== undefined && opt.count"
								>
									{{ index + 1 }}
								</div>
								<div
									class="r-data-list-col-C r-data-list-select-col flx"
									v-if="selectable"
								>
									<r-checkbox
										v-if="selectable"
										v-model="selectedVals"
										:val="selectval ? element[selectval] : element"
									/>
								</div>
								<slot :name="'row' + index" :datarow="element" idrow="i">
									<div
										:class="[
											'r-data-list-col-C flx',
											col.class || '',
											col.cclass || '',
										]"
										:style="[col.style || '', col.cstyle || '']"
										v-for="(col, j) in headdata"
										:key="'col' + j"
									>
										<slot
											:name="['col' + (j + 1)]"
											:datarow="element"
											:datacol="col"
											:idrow="index"
											:idcol="j"
										>
											<div
												class="r-data-list-col-content"
												v-if="col.html && editLineIdi !== index"
												v-html="col.cval ? col.cval(element) : element[col.key]"
											></div>
											<template v-if="!col.html">
												<span
													v-if="editLineIdi !== index"
													class="r-data-list-col-content"
												>
													{{
														col.cval ? col.cval(element) : element[col.key]
													}}</span
												>
											</template> </slot
										><edit-items
											:label="col.title"
											v-if="isEditMode && editLineIdi === index"
											v-model="element[col.key]"
											v-model:valid="isValid[col.key]"
											:opt="col"
										/>
									</div>
									<slot
										:name="'rowbuttons' + index"
										:datarow="element"
										:idrow="index"
									>
										<div
											class="r-data-list-col-C flx r-col-btn-C"
											:style="[opt.buttons.style || '']"
											v-if="opt.buttons"
										>
											<r-btn
												v-if="opt.buttons.edit && editLineIdi !== index"
												:disabled="isEditMode || isAddMode"
												@click="editLine({ row: element, rowid: index })"
												>{{ opt.buttons.edit.txt || 'Düzenle' }}</r-btn
											>
											<r-btn
												bgcolor="#bb0202"
												v-if="opt.buttons.delete && editLineIdi !== index"
												:disabled="isEditMode || isAddMode"
												@click="deleteLineModal({ row: element, rowid: index })"
												>{{ opt.buttons.delete.txt || 'Sil' }}</r-btn
											>
											<r-btn
												bgcolor="#15880d"
												v-if="isEditMode && editLineIdi === index"
												@click="saveLine(editInData)"
												:disabled="canSave"
												>{{ opt.buttons.edit.txt || 'Kaydet' }}</r-btn
											>
											<r-btn
												bgcolor="#868686"
												v-if="isEditMode && editLineIdi === index"
												@click="cancelEdit(index)"
												>{{ opt.buttons.edit.txt || 'Vazgeç' }}</r-btn
											><slot
												name="rowextrabtn"
												:datarow="element"
												:idrow="index"
											>
											</slot>
										</div>
									</slot> </slot
							></slot>
						</div>
					</template>
				</draggable>
			</div>
		</div>
	</div>
	<r-modal
		title="Silmek İstediğinize Emin misiniz?"
		v-if="delmodal"
		v-model:isactive="delmodal"
		:havecontent="false"
	>
		<template v-slot:otherbuttons>
			<r-btn bgcolor="#bb0202" @click="deleteLine(deleteData)">{{
				opt.buttons.delete.txt || 'Sil'
			}}</r-btn>
		</template>
	</r-modal>
</template>
<script>
	import { onBeforeMount, onBeforeUnmount, ref, watch, computed } from 'vue'
	import EditItems from './EditItems'
	import AddNew from './AddNew'
	import { lstore } from './localStore'
	import { gstore } from '../globalstore'
	import draggable from 'vuedraggable'
	//import {trToUpper} from '@/mix/global'
	export default {
		components: {
			EditItems,
			AddNew,
			draggable,
		},
		props: {
			lid: [Number, String],
			title: String,
			intitle: String,
			headdata: {
				type: Array,
				default: new Array(),
			},
			listdata: {
				type: [Array, Object],
				default: new Array(),
			},
			opt: {
				type: Object,
				default: new Object(),
			},
			rowFunc: Function,
			rowDblFunc: Function,
			editMode: {
				type: Boolean,
				default: false,
			},
			addMode: {
				type: Boolean,
				default: false,
			},
			editData: Object,
			saveFunc: Function,
			editLineId: Number,
			disabledrag: {
				type: Boolean,
				default: true,
			},
			draggroup: String,
			search: { type: Boolean, default: true },
			searchfilter: Function,
			qu: String,
			selectable: {
				type: Boolean,
				default: false,
			},
			selectval: String,
			selected: Array,
			dragkey: {
				type: String,
				default: 'id',
			},
			wrapline: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const dblTimeout = ref(null)
			const isEditMode = ref(false)
			const isAddMode = ref(false)
			const isDeleteMode = ref(false)
			const editLineIdi = ref(null)
			const deleteLineId = ref(null)
			const editInData = ref({})
			const showaddnew = ref(false)
			const delmodal = ref(false)
			const deleteData = ref({})
			const q = ref('')
			const selectall = ref(false)
			const selectedVals = ref([])
			const loaded = ref(false)
			const drag = ref(false)
			const isValid = ref({})
			const collapsed = ref(false)
			var persistData = {}

			const dataListStyle = `
		:root{
			--data-list-bg-color:${gstore.style.datalist.bg_color};
			--data-list-header-bg-color:${gstore.style.datalist.header_bg_color};
			--data-list-border-color:${gstore.style.datalist.border_color};
			--data-list-padding:${gstore.style.datalist.padding};
			--data-list-border-rad:${gstore.style.datalist.border_rad}
		}
		`
			const styleTag = document.createElement('style')
			const inData = ref(props.listdata)

			const canSave = computed(() => {
				var dis = false
				Object.keys(isValid.value).forEach((key) => {
					if (!isValid.value[key]) dis = true
				})
				return dis
			})

			const dragOptions = computed(() => {
				return {
					animation: 200,
					group: 'datalistgroup',
				}
			})
			const showHeader = computed(() => {
				if (props.opt.header !== undefined) {
					if (props.opt.header) return true
					else return false
				} else return true
			})
			const rowClick = (d) => {
				if (!dblTimeout.value) {
					dblTimeout.value = setTimeout(() => {
						if (props.rowFunc) props.rowFunc(d)
						dblTimeout.value = null
					}, 175)
				} else {
					if (props.rowDblFunc) props.rowDblFunc(d)
					clearTimeout(dblTimeout.value)
					dblTimeout.value = null
				}
			}
			const editLine = (d) => {
				if (props.opt.buttons.edit && props.opt.buttons.edit.func) {
					props.opt.buttons.edit.func(d)
				} else {
					isEditMode.value = true
					persistData = Object.assign({}, d.row)
					editLineIdi.value = d.rowid
					editInData.value = d.row
					emit('update:editMode', true)
					emit('update:editLineId', d.rowid)
				}
			}
			const deleteLineModal = (d) => {
				isDeleteMode.value = true
				deleteLineId.value = d.rowid
				deleteData.value = d
				delmodal.value = true
			}
			const deleteLine = (d) => {
				if (props.opt.buttons.delete && props.opt.buttons.delete.func) {
					props.opt.buttons.delete.func(d)
				}
				isDeleteMode.value = false
				deleteLineId.value = null
				deleteData.value = {}
				delmodal.value = false
			}
			const saveLine = (d) => {
				if (props.opt.buttons.edit.savefunc) props.opt.buttons.edit.savefunc(d)
				cancelEdit()
			}
			const cancelEdit = (i) => {
				if (i) inData.value[i] = persistData
				isEditMode.value = false
				editLineIdi.value = null
				editInData.value = {}
				emit('update:editMode', false)
				emit('update:editLineId', null)
			}
			const addLine = () => {
				if (props.opt.buttons.add.type === 'in') {
					showaddnew.value = true
					isAddMode.value = true
					emit('update:addMode', true)
				} else {
					props.opt.buttons.add.func()
				}
			}
			const saveNew = (d) => {
				props.opt.buttons.add.func(d)
			}
			const selectAllChange = (e) => {
				if (e.target.checked) {
					selectedVals.value = []
					inData.value.forEach((itm) => {
						selectedVals.value.push(itm[props.selectval])
					})
				} else {
					selectedVals.value = []
				}
				emit('update:selected', selectedVals.value)
			}
			const DragStart = (d) => {
				drag.value = true
				if (props.opt.dragstart) props.opt.dragstart(inData.value, d)
			}
			const DragEnd = (d) => {
				drag.value = false
				emit('update:listdata', inData.value)
				if (props.opt.dragend) props.opt.dragend(inData.value, props.lid, d)
			}
			const DragMove = (d) => {
				if (props.opt.dragmove) props.opt.dragmove(d, inData.value)
			}
			onBeforeMount(() => {
				styleTag.appendChild(document.createTextNode(dataListStyle))
				document.head.appendChild(styleTag)
				selectedVals.value = props.selected
				loaded.value = true
			})
			onBeforeUnmount(() => {
				styleTag.remove()
			})
			watch(
				() => showaddnew.value,
				() => {
					if (showaddnew.value === false) {
						isAddMode.value = false
						emit('update:addMode', false)
					}
				}
			)
			watch(
				() => q.value,
				() => {
					emit('update:qu', q.value)
					if (props.searchfilter)
						inData.value = props.listdata.filter(props.searchfilter)
				}
			)
			watch(
				() => selectedVals.value,
				() => {
					if (selectedVals.value && inData.value) {
						selectall.value =
							selectedVals.value.length === inData.value.length ? true : false
						emit('update:selected', selectedVals.value)
					}
				}
			)
			watch(
				() => props.listdata,
				() => {
					inData.value = props.listdata
				}
			)
			watch(
				() => isEditMode.value,
				() => {
					if (
						props.opt.buttons &&
						props.opt.buttons.edit &&
						props.opt.buttons.edit.type === 'in' &&
						isEditMode.value
					) {
						if (Object.keys(isValid.value).length === 0) {
							props.headdata.forEach((itm) => {
								if (itm.valid) isValid.value[itm.key] = false
							})
						}
					}
				}
			)
			return {
				inData,
				rowClick,
				editLine,
				deleteLine,
				isEditMode,
				isAddMode,
				isDeleteMode,
				editLineIdi,
				deleteLineId,
				editInData,
				lstore,
				saveLine,
				cancelEdit,
				showaddnew,
				addLine,
				saveNew,
				delmodal,
				deleteLineModal,
				deleteData,
				q,
				dragOptions,
				selectall,
				selectedVals,
				selectAllChange,
				loaded,
				DragStart,
				DragEnd,
				DragMove,
				showHeader,
				canSave,
				isValid,
				collapsed,
			}
		},
	}
</script>
<style>
	.r-data-list-W {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.r-data-list-C {
		width: 100%;
		border: 1px solid var(--data-list-border-color);
		border-radius: var(--data-list-border-rad);
	}
	.r-data-list-header-C {
		justify-content: flex-start;
		background-color: var(--data-list-header-bg-color);
		border-radius: var(--data-list-border-rad) var(--data-list-border-rad) 0 0;
		position: relative;
	}
	.r-data-list-title-C {
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding: var(--data-list-padding) 0;
	}
	.r-data-list-title-buttons-C {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
	}
	.r-data-list-list-C {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		flex-direction: column;
		background-color: var(--datalist-bg-color);
	}
	.r-data-list-header-col-C,
	.r-data-list-col-C {
		width: 100%;
		justify-content: flex-start;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
	}
	.r-data-list-header-col-C {
		border-bottom: 2px solid var(--data-list-border-color);
		padding: 12px 6px;
		font-size: 12px;
		align-content: center;
	}
	.r-data-list-col-C {
		border-bottom: 1px solid var(--data-list-border-color);
		padding: 6px;
	}
	.r-data-list-header-col-C:not(:last-child),
	.r-data-list-col-C:not(:last-child) {
		border-right: 1px solid var(--data-list-border-color);
	}
	.r-data-list-line-C {
		border-right: 1px solid transparent;
		border-left: 1px solid transparent;
		background-color: #fff;
	}
	.r-data-list-line-C:nth-child(even) {
		background-color: rgb(245, 245, 245);
	}
	.r-data-list-line-C:hover {
		background-color: rgb(231, 231, 231);
		border-right: 1px solid rgb(0, 56, 160);
		border-left: 1px solid rgb(0, 56, 160);
	}
	.r-data-list-line-C.edit-mode {
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
		z-index: 9;
		border-radius: 6px;
		border: none;
		background-color: #fff;
	}
	.r-data-list-line-C.edit-mode .r-data-list-col-C {
		align-items: flex-start;
	}
	.r-data-list-nodata {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		background-color: #1f1818;
		color: #fff;
		padding: 16px;
	}
	.r-data-list-select-col,
	.r-data-list-count-col {
		width: 50px;
		text-align: center;
		justify-content: center;
	}
	.r-data-list-col-content {
		word-break: normal;
		text-align: left;
		max-width: 100% !important;
	}
	.r-data-list-col-content * {
		max-width: 100% !important;
	}
	.r-data-list-collapse-ico {
		width: 32px;
		height: 100%;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 10px;
		z-index: 9;
		cursor: pointer;
	}
	.r-data-list-collapse-ico.collapsed:before {
		transform: rotate(-45deg);
		top: 12px;
	}
	.r-data-list-collapse-ico:before {
		content: '';
		position: absolute;
		top: 16px;
		border-left: 2px solid #aaa;
		border-bottom: 2px solid #aaa;
		transform: rotate(135deg);
		width: 10px;
		height: 10px;
		transition: 0.2s all ease-in-out;
	}
	.r-data-list-collapse-ico:hover:before {
		border-color: #842929;
	}
</style>
